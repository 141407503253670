.main {
  align-items: center;
  background: url('../images/bg-home.jpg') center center / cover  no-repeat;
  color: #040767;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  padding: 15px;
}
.box-logo {
  max-width: 100%;
  width: 500px;
}
.box-logo img {
  width: 100%;
}
.logo {
  color: var(--primaryColor);
  font-family: var(--secundaryFontFamily);
  font-size: 150px;
  position: relative;
}
.logo:before{
  color: var(--secundaryColor);
  content: 'studio';
  display: block;
  font-size: 45px;
  position: absolute;
  right: -10px;
  top: 35px;
}
.logo:after{
  bottom: 0;
  color: var(--darkRed);
  content: 'ESTÉTICA E BEM ESTAR';
  display: block;
  font-family: var(--primaryFontFamily);
  font-size: 12px;
  font-weight: 300;
  left: 45px;
  position: absolute;
  width: 90px;
}

.box-servicos {
  margin-top: 30px;
  padding: 0 20px;
}

.box-servicos h2 {
  margin-bottom: 50px;
  text-align: center;
}
.box-servicos__content {
  margin-bottom: 20px ;
}
.box-servicos__content h3 {
  margin-bottom: 5px ;
}
.box-servicos__lista-servico {
  font-family: var(--secundaryFontFamily);
  font-size: 18px;
  font-weight: 400;
}

.box-rede-social p{
  margin-bottom: 10px;
  text-align: center;
}
.box-rede-social__lista{
  display: flex;
  justify-content: center;
}
.box-rede-social__lista li {
  margin: 0 5px;
}

.ico {
  display: block;
  height: 40px;
  overflow: hidden;
  text-indent: 9999px;
  transition: opacity 0.5s ease-in-out;
  width: 40px;
}
.ico:hover {
  opacity: 0.8;
}
.facebook{
  background: url('../images/ico-facebook.svg') center center / cover no-repeat;
}
.instagram{
  background: url('../images/ico-instagram.svg') center center / cover no-repeat;
}
.whatsapp{
  background: url('../images/ico-whatsapp.svg') center center / cover no-repeat;
}

@media (min-width: 1024px) {
  .box-servicos {
    padding: 0 120px;
  }
}

@media (min-width: 768px) {
  .box-servicos {
    padding: 0 80px;
  }
}