:root {
  --primaryFontFamily: 'Roboto', sans-serif;
  --secundaryFontFamily: 'Bellota', cursive;
  --primaryColor: #cc4a8c;
  --secundaryColor: #e590bd;
  --backgroundColor: #f0f0f5;
  --borderColor: #dcdce6;
  --darkColor: #333333;
  --blackColor: #000000;
  --whiteColor: #ffffff;
  --darkRed: #961a4b;
}