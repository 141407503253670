@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&display=swap');


* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px var(--primaryFontFamily);
  -webkit-font-smoothing: antialiased;
}

body, html {
  height: 100vh;
}

input,
button,
textarea {
  font: 400 18px var(--primaryFontFamily);
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

input {
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  color: var(--darkColor);
  font-size: 13px;
  font-weight: 300;
  height: 30px;
  padding: 0 10px;
}
input:focus{
  box-shadow:0 0 10px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.button {
  background: var(--primaryColor);
  border: 0;
  border-radius: 8px;
  color: var(--whiteColor);
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  margin-top: 16px;
  text-align: center;
  text-decoration: none;
  transition: filter 0.2s;
  width: 100%;
}

.button:hover {
  filter: brightness(90%);
}


.rotate{
  animation: fa-spin 2s infinite linear;
}

/* Animation */
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}