@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&display=swap);
.main {
  align-items: center;
  background: url(/static/media/bg-home.fd11bbbd.jpg) center center / cover  no-repeat;
  color: #040767;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  padding: 15px;
}
.box-logo {
  max-width: 100%;
  width: 500px;
}
.box-logo img {
  width: 100%;
}
.logo {
  color: var(--primaryColor);
  font-family: var(--secundaryFontFamily);
  font-size: 150px;
  position: relative;
}
.logo:before{
  color: var(--secundaryColor);
  content: 'studio';
  display: block;
  font-size: 45px;
  position: absolute;
  right: -10px;
  top: 35px;
}
.logo:after{
  bottom: 0;
  color: var(--darkRed);
  content: 'ESTÉTICA E BEM ESTAR';
  display: block;
  font-family: var(--primaryFontFamily);
  font-size: 12px;
  font-weight: 300;
  left: 45px;
  position: absolute;
  width: 90px;
}

.box-servicos {
  margin-top: 30px;
  padding: 0 20px;
}

.box-servicos h2 {
  margin-bottom: 50px;
  text-align: center;
}
.box-servicos__content {
  margin-bottom: 20px ;
}
.box-servicos__content h3 {
  margin-bottom: 5px ;
}
.box-servicos__lista-servico {
  font-family: var(--secundaryFontFamily);
  font-size: 18px;
  font-weight: 400;
}

.box-rede-social p{
  margin-bottom: 10px;
  text-align: center;
}
.box-rede-social__lista{
  display: flex;
  justify-content: center;
}
.box-rede-social__lista li {
  margin: 0 5px;
}

.ico {
  display: block;
  height: 40px;
  overflow: hidden;
  text-indent: 9999px;
  transition: opacity 0.5s ease-in-out;
  width: 40px;
}
.ico:hover {
  opacity: 0.8;
}
.facebook{
  background: url(/static/media/ico-facebook.17133652.svg) center center / cover no-repeat;
}
.instagram{
  background: url(/static/media/ico-instagram.60495401.svg) center center / cover no-repeat;
}
.whatsapp{
  background: url(/static/media/ico-whatsapp.a6ffdaf5.svg) center center / cover no-repeat;
}

@media (min-width: 1024px) {
  .box-servicos {
    padding: 0 120px;
  }
}

@media (min-width: 768px) {
  .box-servicos {
    padding: 0 80px;
  }
}
:root {
  --primaryFontFamily: 'Roboto', sans-serif;
  --secundaryFontFamily: 'Bellota', cursive;
  --primaryColor: #cc4a8c;
  --secundaryColor: #e590bd;
  --backgroundColor: #f0f0f5;
  --borderColor: #dcdce6;
  --darkColor: #333333;
  --blackColor: #000000;
  --whiteColor: #ffffff;
  --darkRed: #961a4b;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px var(--primaryFontFamily);
  -webkit-font-smoothing: antialiased;
}

body, html {
  height: 100vh;
}

input,
button,
textarea {
  font: 400 18px var(--primaryFontFamily);
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

input {
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  color: var(--darkColor);
  font-size: 13px;
  font-weight: 300;
  height: 30px;
  padding: 0 10px;
}
input:focus{
  box-shadow:0 0 10px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}

.button {
  background: var(--primaryColor);
  border: 0;
  border-radius: 8px;
  color: var(--whiteColor);
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  margin-top: 16px;
  text-align: center;
  text-decoration: none;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  width: 100%;
}

.button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}


.rotate{
  animation: fa-spin 2s infinite linear;
}

/* Animation */
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
